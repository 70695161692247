.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-basis: calc(100% / 4); /* Maximum 4 items per row */
  box-shadow: 0 0 20px rgba(248, 248, 248, 0.4);
}

.product:hover {
  transform: scale(1.03);
  transition: all 0.3s;
}

.product-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 10px;
}

.product-price {
  font-weight: bold;
}

.product-button {
  border: 1px solid #ccc;
  background: none;
  cursor: pointer;
  color: white;
  padding: 6px 12px;
}

.product-button:hover {
  transform: scale(1.3);
  transition: all 0.3s;
}
